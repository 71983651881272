@import '~bootstrap/dist/css/bootstrap.min.css';
@import "assets/scss/bootstrap-overrides";
@import "assets/scss/mixins/mixin";
@import "assets/scss/variables";
@import "assets/fonts/icomoon/style";
@import "../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css";


html , body{
  font-family: $Lato-Regular;
  background: #EBEEF4;
  margin: 0;
}
html {
  font-size: 14px;
  //@media screen and (max-width: 1366px) {
  //  font-size: 14px;
  //}
  @media screen and (max-width: 1366px) {
    font-size: 13px;
    ::-webkit-scrollbar {
      width: 0;
    }
    ::-webkit-scrollbar-track {
      background: transparent !important;
    }
    ::-webkit-scrollbar-thumb {
      border: 0 solid transparent;
    }
  }
  @media screen and (max-width: 380px) {
    font-size: 11px;
  }
  @media (-webkit-device-pixel-ratio: 1.25) {
    font-size: 12px;
  }
  @media screen and (min-width: 991px) {
    ::-webkit-scrollbar {
      width: 8px;
      height: 0;
    }
    ::-webkit-scrollbar-track {
      border-radius: 5px;
      background: rgba($dark, 0.3) !important;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 20px;
      border: 2px solid $dark;
      background: rgba($dark, 1) !important;
    }
    @media (min-resolution: 1.25dppx) {
      font-size: 13px;
    }
  }

  @media (min-resolution: 1.25dppx) {
    font-size: 13px;
  }

  a:hover {
    text-decoration: none !important;
  }

  @media (min-width: 768px) and (-webkit-min-device-pixel-ratio: 1.25), (min-width: 768px) and (min-resolution: 1.25dppx){
    font-size: 13px;
  }
  @media (min-width: 1100px) and (-webkit-min-device-pixel-ratio: 1.25), (min-width: 1100px) and (min-resolution: 1.25dppx){
    font-size: 13px;
  }
}

input::placeholder {
  color: #A1A1A1 !important;
  font-size: 12px;
}
a{
  color: $primary;
}
.font-extraBold{
  font-family: $Lato-ExtraBold;
}
.font-bold{
  font-family: $Lato-Bold;
}
.font-light{
  font-family: $Lato-Light;
}
.font-black{
  font-family: $Lato-Black;
}
.font-regular{
  font-family: $Lato-Regular;
}
.font-medium{
  font-family: $Lato-Medium;
}
.font-semiBold{
  font-family: $Lato-SemiBold;
}

.btn{
  min-width: 133px;
  height: 44px;
  border-radius: 60px;
  &.sm-btn{
    min-width: 80px;
  }

}


.input-group-text{
  background: transparent !important;
  position: absolute;
  right: 0;
  border: none !important;
  z-index: 99;

}

.form-label{
  font-family: $Lato-Regular;
  font-size: 15px;
}
.modal{
  .form-label{
    font-family: $Lato-Bold;
  }
}
.form-control, .form-select{
  height: 48px;
  background: white;
  border: 1px solid rgba($primary, 0.16) !important;
  border-radius: 60px !important;
  padding: 0 33px 0 20px;

  &.search-input{
    border: none !important;
    border-bottom: 2px solid rgba(255, 255, 255, 0.10) !important;
    border-radius: 0 !important;
    background: transparent !important;
    color:white;
    padding-left: 40px !important;
    &:focus {
      box-shadow: none !important;
    }
  }
}
.date-picker{
  position: relative;
  &:after {
    content: $icon-calendar;
    font-family: $icomoon-font-family;
    position: absolute;
    right: 17px;
    top: 9px;
    font-size: 20px;
    color: #DADADA;
    pointer-events: none;
    cursor: pointer;
  }
}

.job-filter{
  .input-group{
    max-width: 220px;
    .form-control{
      &.search-input{
        border: 1px solid white !important;
        border-radius: 67px !important;
        max-width: 220px;
      }
    }
  }
}
.input-group-text{
  background: transparent;
  border:none;
  height: 48px;
  cursor: pointer;
  padding-right: 20px;
  color: $gray !important;
}

.text-gray{
  color: $gray !important;
}
.text-lighter-blue{
  color: $lighter-blue !important;
}
.border-light-blue{
  border-color: $lighter-blue;
}
.border-right{
  border-right: 2px solid rgb(160 195 254 / 50%);
}
.text-dark-gray{
  color: $dark-gray !important;
}
.text-yellow{
  color: $yellow !important;
}

.border-top{
  border-top:1px solid $border;
}
.border-top{
  border-top:1px solid $border;
}
.border-dark-gray{
  border-color: $dark-gray;
}
.checkbox-custom {
  opacity: 0;
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  width: 19px;
  height: 19px;

  .checkbox-custom-label {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    position: relative;
  }

  & + .checkbox-custom-label {
    background: white;
    border: 1px solid rgba($primary, 0.16) !important;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    padding: 2px;
    margin-right: 10px;
    text-align: center;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
  }

  &:checked {
    & + .checkbox-custom-label {
      background: white;

      &:before {
        font-family: $icomoon-font-family;
        content: $icon-check;
        color: black;
        position: absolute;
        top: -3px;
        left: 2px;
        text-shadow: 0 3px 7px rgba(41, 41, 41, 0.61);
        font-size: 13px;

      }
    }
  }

  & + label {
    cursor: pointer;
  }
}

.text-light-green{
  color: $light-green;
}
.text-red{
  color: $red;
}
.text-blue{
  color: $blue;
}
.text-light-blue{
  color: $light-blue;
}
.cursor-pointer{
  cursor: pointer;
}


.construction{
  display: flex;
  justify-content: center;
  img{
    width: 470px !important;
  }
}

.custom-select{
  position: relative;
  &:after{
    content: $icon-chevron-down;
    font-family: $icomoon-font-family;
    position: absolute;
    right: 15px;
    bottom: 14px;
    font-size: 11px;
    color: #a1a1a1;
    cursor: pointer;
    pointer-events: none;
  }
  &.with-title{
    display: flex;
    align-items: center;
    width: 219px;
    background: transparent !important;
    border: 1px solid white !important;
    padding-left: 20px;
    border-radius: 60px;
    //color: white;
    label{
      color: rgba(white, 0.5);
      width: 90px;
    }
    .form-control, .form-select {
      background: transparent !important;
      color: white;
      border: none !important;
      padding-left: 0 !important;
      &:focus{
        box-shadow: none !important;
      }
    }
  }
}
.select-element{
  position: relative;
  &:after{
    content: $icon-chevron-down;
    font-family: $icomoon-font-family;
    position: absolute;
    right: 30px;
    bottom: 14px;
    font-size: 11px;
    color: white;
    cursor: pointer;
    pointer-events: none;
  }
}
.dropdown-notif{
  position: relative;
  .btn{
    width: auto;
    min-width: 20px;
  }
  &.show{
    .dropdown-menu{
      right: 0;
      transform: translate3d(25px, 65px, 0px) !important;
      box-shadow: 7px 6px 34px 0 rgba(17, 106, 204, 0.20);
      left: unset !important;
      border-radius: 40px;
      padding: 15px;
      width: 363px;
      p{
        white-space: initial;
      }
      &:before{
        content: '';
        border-bottom: 23px solid white;
        border-left: 23px solid transparent;
        border-right: 23px solid transparent;
        position: absolute;
        top: -17px;
        right: 40px;
      }
      .dropdown-item{
        padding: 10px 0 10px 0;
        margin-bottom: 10px;
        border-bottom: 1px solid rgba($light-blue, 0.48);
        &:active{
          background: #f8f9fa;
        }
      }
    }
    &.dropdown-action{
      .dropdown-menu{
        border-radius: 20px;
        padding: 5px;
        width: 150px;
        right: 20px;
        top: -10px !important;
        &:before{
          content: '';
          border-bottom: 13px solid white;
          border-left: 13px solid transparent;
          border-right: 13px solid transparent;
          position: absolute;
          top: -14px;
          right: 20px;
        }
        //.dropdown-item{
        //  padding: 10px 0 10px 0;
        //  margin-bottom: 10px;
        //  border-bottom: 1px solid rgba($light-blue, 0.48);
        //  &:active{
        //    background: #f8f9fa;
        //  }
        //}
      }
    }
  }

}

.private-dash{
  //margin-top: -100px;
}
.card-job{
  border-radius: 40px;
  background: #F2F7FE;
  position: relative;
  height: 159px;
  padding: 20px;
  overflow: hidden;
  &.red{
    box-shadow: 7px 17px 54.4px 0 rgba(253, 78, 93, 0.12);
    em{
      color: rgba(#FD4E5D, 0.23);
    }
    &:hover{
      background: #FD4E5D;
      color:white !important;
      em{  color:white !important;}
    }
  }
  &.green{
    box-shadow: 7px 17px 54.4px 0 rgba(146, 227, 169, 0.12);
    em{
      color: rgba(#92E3A9, 0.55);
    }
    &:hover{
      background: #92E3A9;
    }
  }
  &.blue{
    box-shadow: 7px 17px 54.4px 0 rgba(160, 195, 255, 0.13);
    em{
      color: rgba(#A0C3FF, 0.49);
    }
    &:hover{
      background: #A0C3FF;
    }
  }
  em{
    position: absolute;
    right: -12px;
    bottom: 0;
    z-index: 9;
  }
  &:hover{
    color:white !important;
    em, h2 {  color:white !important;}
  }
  &.sm-card{
     height: 130px;
  }
}
.card-user{
  padding: 15px 10px;
  border-radius: 35px;
  background: #EBEEF2;
  box-shadow: 7px 17px 54.4px 0 rgba(17, 106, 204, 0.12);
  position: relative;
  min-height: 90%;
  overflow: hidden;
  .card-element{
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    &:not(:last-child){
      border-bottom: 1px solid rgba(#A0C3FF, 0.48);
    }
  }
  &:after{
    content: url("./../src/assets/img/logo-shape.png");
    position: absolute;
    bottom: -8px;
    right: 0;
    pointer-events: none;
  }
}
.min-height-auto{
  min-height: auto !important;
}
.recent-job{
  border-radius: 40px;
  background: #EBEEF2;
  box-shadow: 7px 17px 54.4px 0 rgba(17, 106, 204, 0.12);
  padding: 45px 10px;
  @media screen and (min-width: 991px){
    padding: 45px 35px 25px 35px;
  }
  position: relative;
  &:after{
    content: url("./../src/assets/img/logo-shape.png");
    position: absolute;
    bottom: -8px;
    right: 0;
    pointer-events: none;
  }
}
.user-picture{
  width: 74px;
  height: 74px;
  border-radius: 15px;
}
.recent-card-job{
  border-radius:20px;
  background: rgba(255, 255, 255, 0.21);
  box-shadow: 7px 17px 54.4px 0px rgba(17, 106, 204, 0.12);
  padding: 20px 30px;
  margin-bottom: 1.5rem;
}
.job-details{
  border-radius: 7px;
  border: 1px solid #116ACC;
  min-width: 80px;
  height: 36px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.switch-btn{
   border-radius: 67px;
   border: 1px solid #FFF;
   width: 219px;
    display: flex;
    height: 48px;
    padding: 5px;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
  .btn {
    height: 37px;
    background: transparent;
    min-width: auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 67px !important;
    padding: 0 !important;
    input{
      opacity: 0;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
    label{
      color: white;
    }
    &.active{
      background: white;

      label{
        color: $dark;
      }
    }
  }
}


.header {
  background-color: $primary;
  padding: 20px 45px;
  font-size: 35px;
  height: 200px;
  border-radius: 0 0 40px 40px;
  @media screen and ( max-width: 991px){
    height: 90px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 96;
  }
}
.content-body{
  margin-top: -110px;
  @media screen and (max-width: 991px){
    margin-top: 0;
    padding-top: 110px;
  }
}
.header-element:not(:last-child){
  border-right: 2px solid rgba(white, 0.1);
}
.search-group{
  .input-group-text{
    right: unset !important;
    left: 0;
    em{
      color: rgba(white, 0.3) !important;
    }
  }
}
.h-fit-content{
  height: fit-content;
}
.picture-container {
  position: relative;
  display: flex;
  .picture {
    &:not(:first-child){
      margin-left: -25px; // Adjust the spacing between pictures
    }
    img {
      width: 34px;
      height: 34px;
      display: block;
      border-radius: 50%;
      border: 2px solid white;
    }
  }
}

.box-shadow{
  box-shadow: 7px 17px 54.4px 0 rgba(17, 106, 204, 0.12);
}

.mt-n3{
  margin-top: -1rem;
}

.product-picture{
  min-width: 67px;
  height: 67px;
  border: 1px solid rgba(17, 106, 204, 0.29);
  border-radius: 15px;
  background: #F2F7FE;
  padding: 10px;
  &.modal-picture{
    width: 108px;
    height: 108px;
  }
}
.status{
  width: 39px;
  height: 39px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  &.active{
    background: $green;
  }
  &.archived{
    background: $gray;
  }
  &.inactive{
    background: $red;
  }
}
.tab-content{
  .accordion-button{
    &:after{
      content: none;
    }
  }
  .accordion-item{
    border: none;
    background: transparent;
  }
}
.accordion-collapse {
  padding-top: 10px;
    .accordion-body{
      background: rgba(white, 0.29);
      border-radius: 26px;
      border: 1px solid rgba(160, 195, 255, 0.49);
      box-shadow: 7px 17px 54.4px 0 rgba(17, 106, 204, 0.12);
    }
}
.border-right{
  border-right: 1px solid $border;
}
/************ modal styling *************/

.modal-backdrop{
  background: linear-gradient(359deg, rgba(24, 34, 51, 0.78) 6.41%, rgba(24, 34, 51, 0.00) 98.76%), rgba(24, 34, 51, 0.78);

  & + .modal-backdrop{
    display: none;
  }
}
.modal{
  display: flex !important;
  align-items: center;
}
.modal-dialog{
  max-width: 100% !important;
  &.custom-modal-calendar{
    width: 470px;
  }
  &.add-job-modal{
    width: 990px;
  }
}

.modal-dialog{
  margin-top: 30px;
  .modal-content{
    max-width: 85vw;
    margin: auto;
    padding:25px ;
    border-radius: 40px;
    background: #EBEEF2;
    box-shadow: 7px 17px 54.4px 0 rgba(17, 106, 204, 0.12);
    max-height: 90vh;
    .modal-body{
      max-height: 75vh;
      overflow: auto;

    }
    .btn-close{
      position: absolute;
      right: 60px;
      top: -15px;
      width: 28px;
      height: 28px;
      background: $red;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      opacity:1;
      filter: drop-shadow(3px 2px 5.5px rgba(186, 0, 0, 0.11));
      &:focus{
        box-shadow: none !important;
      }
    }
  }
  &.custom-modal-confirm{
    .modal-content{
      max-width: 450px;
    }
  }
  &.custom-modal-skill{
    .modal-content{
      width: 850px;
    }
  }
  &.custom-modal-meting{
    .modal-content{
      width: 636px;
    }
  }
}


.btn-primary{
  background: $primary !important;
  border-color: $primary !important;
  &:hover, &:focus{
    background: $primary !important;
    border-color: $primary !important;
    box-shadow: none !important;
  }
}
.btn-outline-primary{
  border-color: $primary !important;
  color: $primary !important;
  &:hover, &:focus{
    background: transparent !important;
    border-color: $primary !important;
    color: $primary !important;
    box-shadow: none !important;
  }
}
.btn-light-green{
  border-color: $light-green !important;
  background: $light-green !important;
  &:hover, &:focus{
    border-color: $light-green !important;
    background: $light-green !important;
    color: white !important;
    box-shadow: none !important;
  }
}
.btn-light-gray{
  border-color: $lightGray2 !important;
  background: $lightGray2 !important;
  &:hover, &:focus{
    border-color: $lightGray2 !important;
    background: $lightGray2 !important;
    color: white !important;
    box-shadow: none !important;
  }
}


.modal-tabs{
  .nav-tabs{
     min-height: 62px;
    padding: 0 30px;
    margin: 15px 0;
    .nav-link {
      font-family: $Lato-Bold;
      font-size: 20px;
      min-height: 60px;
      border: none;
      color: $dark;
      &.active{
        border-bottom:2px solid $primary;
        color: $primary;
      }
    }
  }
}

/********* responsive ***********/
@media screen and (min-width: 991px){
  .card-user{
    padding: 15px 10px 15px 15px;
  }
  .pt-45{
    padding-top: 45px;
  }
  .custom-modal-applyJob{
    min-width: 670px;
  }
  .text-lg-white{
    color: white;
  }
}
@media screen and (max-width: 991px){
  .fc .fc-button-group > .fc-button {
    flex: 1 1 auto;
    position: relative;
    margin: 3px !important;
  }
  .text-mob-primary{
    color: $primary !important;
  }
  .switch-btn{
    border: 1px solid $primary;
    .btn {
      background: transparent;
      label{
        color: $primary;
      }
      &.active{
        background: $dark;

        label{
          color: white;
        }
      }
    }
  }

  .job-filter{
    .input-group{
      .form-control{
        &.search-input{
          border: 1px solid $primary !important;
          color: $primary !important;
        }
      }
    }
  }
  .filter-bloc{
    box-shadow: 7px 17px 54.4px 0 rgba(17, 106, 204, 0.12);
    background: white;
    padding: 15px;
    border-radius: 26px;
    .custom-select{
      &.with-title{
        border: 1px solid $primary !important;
        label{
          color: $primary;
        }
        .form-control, .form-select {
          color: $primary;

        }
      }
    }
  }

}



.more-btn{
  background: transparent;
  border: none;
}

.button-icon{
  background: white;
  border: 1px solid rgba($blue, 0.29);
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  min-width: 45px;
}


.dropdown-toggle{
  &:after{
    content: none !important;
  }
}

.fc-toolbar-chunk{
  display: flex;
  align-items: flex-start;
  .fc-button-group{
    flex-wrap: wrap;
    .fc-button{
      border-radius: 20px !important;
      margin: 0 10px;
      min-width: 65px !important;
    }
  }
  .fc-prev-button , .fc-next-button{
    background: transparent;
    border:none;
    padding: 0;
    min-width: 25px !important;
    &:hover, &:focus, &:active{
      box-shadow: none !important;
      background: transparent !important;
      border:none !important;
    }
    .fc-icon{
      color: #116ACC;
      font-size: 30px !important;
    }
  }

}


.fc-v-event {
  background-color: #F1FBFF !important;
  border: none;
  display: block;
  .fc-event-main {
    color: #1BA1EB !important;
    padding-left: 15px;
    &:before{
      content: "";
      position: absolute;
      width: 8px;
      height: 100%;
      background: #1BA1EB;
      left: -2px;
      border-radius: 5px 0 0 5px;
    }
  }
}
.fc .fc-timegrid-col-events {
  width: 100%;
}
.fc-scroller{
  overflow: hidden auto !important;
}

.bs-calendar-container{
  .theme-green .bs-datepicker-head {
    background-color: transparent !important;
  }
}
.theme-green .bs-datepicker-head {
  background-color: transparent !important;
  span{
    color: black;
    font-family: $Lato-Medium;
    font-size: 14px;
  }
}

.bs-datepicker{
  background: #F2F7FE;
  box-shadow: 0 1px 3px 0 rgba(19, 10, 46, 0.13), 0 3px 14px 0 rgba(19, 10, 46, 0.03), 0 8px 32px 0 rgba(19, 10, 46, 0.07), 0 30px 84px 0 rgba(19, 10, 46, 0.08);
  border-radius: 12px;
  .bs-datepicker-body {
    padding: 0;
    border:none;
    table{
      &.days span{
        width: 42px;
        height: 32px;
      }
      td span.selected, td.selected span,  td span[class*=select-]:after, td[class*=select-] span:after {
        background-color: $primary;
        border-radius: 40px;
      }
      thead th:first-child, td.week{
        display: none;
      }

    }
  }
}

.btn-add{
  background: #92E3A9;
  border-radius: 50%;
  width: 39px;
  min-width: 39px;
  height: 39px;
}
.text-area{
  min-height: 130px !important;
  border-radius: 28px !important;
  border: 1px solid rgba(17, 106, 204, 0.16);
  background: #FFF;
}


.pagination{
  .pagination-page{

  }
  .page-item{
    .page-link{
      background: transparent;
      border: none;
      color: $gray-2;
      margin: 0 1px;
      font-family: $Lato-Bold;
      font-size: 16px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:hover,
    &.active{
      background: $primary;
      border-radius:7px;
      .page-link{
        color: white;
      }
    }
    &.pagination-first,
    &.pagination-last,
    &.pagination-next,
    &.pagination-prev{
      .page-link{
        font-size: 26px;
      }
    }
  }
}
.custom-card{
  background: #EBEEF2;
  padding: 20px;
  margin-top: 80px;
  border-radius: 40px;
  box-shadow: 7px 17px 54.4px 0 rgba(17, 106, 204, 0.12);
}

.edit-card{
  border-radius: 26px;
  border: 1px solid rgba(160, 195, 255, 0.48);
  background: rgba(255, 255, 255, 0.21);
  box-shadow: 7px 17px 54.4px 0 rgba(17, 106, 204, 0.12);
  padding: 10px 20px;
  &.dashed{
    //background: transparent;
    //border: 1px dashed rgba(160, 195, 255, 0.60);
    background-image: $border-dash;
    border:none;
    box-shadow: none;
    .form-control{
      //border: 1px dashed rgba(160, 195, 255, 0.60) !important;
      box-shadow: 7px 17px 54.4px 0 rgba(17, 106, 204, 0.12);
      background: transparent;
    }
  }
}
.edit-profile-picture{
  position: relative;
  display: flex;
  min-width: 64px;
  height: 65px;
  justify-content: center;
  background-image: $border-dash-2;
  border-radius: 15px;
  align-items: center;
  .edit{
    background: #365072;
    position: absolute;
    height: 22px;
    width: 22px;
    color: white;
    right: -5px;
    bottom: -5px;
    border-radius: 50%;

  }
}

.simple-card{
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.21);
  box-shadow: 7px 17px 54.4px 0 rgba(17, 106, 204, 0.12);
  padding:15px 10px;
  p{
    color: $secondary;
  }
  &.tabs-role{
    margin-bottom: 1.5rem;
    &.active , &:hover{
      border-radius: 20px;
      background:$primary;
      box-shadow: 7px 17px 54.4px 0 rgba(54, 80, 114, 0.12);
      color: white !important;
      p{
        color: white !important;
      }
    }
  }
}

.form-check.form-switch{
  .form-check-input{
    width: 79px !important;
    height: 35px !important;
    &:focus {
      box-shadow: none;
    }
  }
}

.recommended-element{
  width: 60px;
  height: 60px;
  border-radius: 15px;
  border: 1px solid rgba(17, 106, 204, 0.29);
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  img{
    width: -webkit-fill-available;
    border-radius: 10px;
  }
}
em{
  cursor: pointer;
}
.progress, .progress-stacked {
  --bs-progress-bg: rgb(158 194 255 / 31%);
  --bs-progress-bar-bg: #A0C3FF;
  border-radius: 10px;
}
.progress-bar{
  border-radius: 6px;
}
.add-btn{
  border-radius: 67px;
  background: #A5C5ED;
  color: $dark;
}
