@mixin lineClamp($number){
  @for $i from 1 through $number{
    .line-clamp-#{$i}{
      display: -webkit-box;
      max-width: 100%;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: $i;
    }
  }
}
