
@import "node_modules/bootstrap/scss/mixins/breakpoints";

@mixin font-face($style-name, $file, $family, $category: "") {
  $filepath: "../../fonts/" + $family + "/" + $file;
  @font-face {
    font-family: "#{$style-name}";
    src: url($filepath + ".ttf");
    src: url($filepath + ".eot?#iefix") format("embedded-opentype"),
      url($filepath + ".woff") format("woff");
  }
  %#{$style-name} {
    font: {
      @if $category != "" {
        family: "#{$style-name}", #{$category};
      } @else {
        family: "#{$style-name}";
        weight: normal;
      }
    }
  }
}



  @mixin fontSize($size: 1px) {
    @for $i from 5 through 100 {
      .fs-#{$i} {
        font-size: $i+px  !important;
      }
    }
  }


