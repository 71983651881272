@import "mixins/fonts_mixin";

@include font-face('Lato-ExtraBold', 'Lato-ExtraBold', 'lato', 'sans-serif');
@include font-face('Lato-Black', 'Lato-Black', 'lato', 'sans-serif');
@include font-face('Lato-Bold', 'Lato-Bold', 'lato', 'sans-serif');
@include font-face('Lato-Semibold', 'Lato-Semibold', 'lato', 'sans-serif');
@include font-face('Lato-Medium', 'Lato-Medium', 'lato', 'sans-serif');
@include font-face('Lato-Regular', 'Lato-Regular', 'lato', 'sans-serif');
@include font-face('Lato-Light', 'Lato-Light', 'lato', 'sans-serif');

@include fontSize($size: 0.35714285714285715rem);

$Lato-ExtraBold: "Lato-ExtraBold", sans-serif;
$Lato-Bold: "Lato-Bold", sans-serif;
$Lato-SemiBold: "Lato-Semibold", sans-serif;
$Lato-Black: "Lato-Black", sans-serif;
$Lato-Medium: "Lato-Medium", sans-serif;
$Lato-Regular: "Lato-Regular", sans-serif;
$Lato-Light: "Lato-Light", sans-serif;


$dark-gray : #8A92A6;
$gray: #C7C7C7;
$gray-2: #9CA3AF;
$primary : #365072;
$green: #5CA829;
$orange:#E67300;
$yellow:#FFD02B;
$secondary : #A5C5ED;
$blue : #116ACC;
$red: #BA0000;
$purple: #514F84;
$light-green : #92E3A9;
$light-red : #FD4E5D;
$light-blue : #A0C3FF;
$lighter-blue : rgba(160, 195, 255, 0.48);
$border : rgba($light-blue, 0.48);
$lightGray: #F8F8F9;
$lightGray2: #DADADA;
$dark : #182233;
$redColor: $red;

$border-dash : url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='26' ry='26' stroke='%23A0C3FF7A' stroke-width='2' stroke-dasharray='6%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
$border-dash-2 : url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23A0C3FF7A' stroke-width='2' stroke-dasharray='6%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
